<script>
import { getDefaultProductPlaceholderImage } from '@/@core/utils/utils'

export default {
  methods: {
    calculateImageRatio(imageClassId) {
      const element = this.$el.getElementsByClassName(imageClassId)
      if (element.length) {
        const width = element[0].naturalWidth
        const height = element[0].naturalHeight
        // Calculate the image resolution and place the position of the image
        // at every load of the image

        if (!(height <= 1 && width <= 1)) {
          const ratio = height / width
          if (ratio <= 1.48) {
            element[0].classList.add('h-100')
          } else {
            element[0].classList.remove('h-100')
          }
        }
      }
    },
    imageLoadError(event) {
      event.target.src = getDefaultProductPlaceholderImage()
    },
  },
}
</script>
